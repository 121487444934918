<template>
  <div>
    <div id="carouselExampleIndicators" class="carousel slide carousel-fade" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item" v-for="link in imageLinks" :key="link" :class="{ active: link === imageLinks[0] }">
          <div class="row justify-content-center">
            <div class="col">
              <img :src="link" class="d-inline" style="max-width: 100%;">
            </div>
          </div>
        </div>
      </div>
    </div>

    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true" style="border-radius: 50%;"></span>
      <span class="visually-hidden">Previous</span>
    </button>

    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true" style="border-radius: 50%;"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>
<script>
export default {
  name: 'Photo Gallery',
  data() {
    return {
      imageLinks: [
        '/images/large/20140412_145006 Large.jpeg',
        '/images/large/20140528_090620 Large.jpeg',
        '/images/large/20140725_112911 Large.jpeg',
        '/images/large/20140729_092107 Large.jpeg',
        '/images/large/20140804_143332 Large.jpeg',
        '/images/large/20140829_105223 Large.jpeg',
        '/images/large/20140919_150658 Large.jpeg',
        '/images/large/20141001_083458 Large.jpeg',
        '/images/large/20141114_152103 Large.jpeg',
        '/images/large/20141205_151151 Large.jpeg',
        '/images/large/20141205_153046 Large.jpeg',
        '/images/large/20141209_114927 Large.jpeg',
        '/images/large/20141209_115006 Large.jpeg',
        '/images/large/20141215_172611 Large.jpeg',
        '/images/large/20150109_095133 Large.jpeg',
        '/images/large/20150109_140128-EFFECTS Large.jpeg',
        '/images/large/20150129_114600 Large.jpeg',
        '/images/large/20150130_181912 Large.jpeg',
        '/images/large/20150213_151606 Large.jpeg',
        '/images/large/20150226_170554 Large.jpeg',
        '/images/large/20150226_170624 Large.jpeg',
        '/images/large/20150308_103647 Large.jpeg',
        '/images/large/20150509_100313 Large.jpeg',
        '/images/large/20150521_091157 Large.jpeg',
        '/images/large/20150522_143025 Large.jpeg',
        '/images/large/20151007_101803 Large.jpeg',
        '/images/large/IMG_0008 Large.jpeg',
        '/images/large/IMG_0010 Large.jpeg',
        '/images/large/IMG_0020 Large.jpeg',
        '/images/large/IMG_0149 Large.jpeg',
        '/images/large/IMG_0154 Large.jpeg',
        '/images/large/IMG_0355 Large.jpeg',
        '/images/large/IMG_0357 Large.jpeg',
        '/images/large/IMG_0360 Large.jpeg',
        '/images/large/IMG_0373 Large.jpeg',
        '/images/large/IMG_0374 Large.jpeg',
        '/images/large/IMG_0426 Large.jpeg',
        '/images/large/IMG_0473 Large.jpeg',
        '/images/large/IMG_0593 Large.jpeg',
        '/images/large/IMG_0596 Large.jpeg',
        '/images/large/IMG_0658 Large.jpeg',
        '/images/large/IMG_0664 Large.jpeg',
        '/images/large/IMG_0665 Large.jpeg',
        '/images/large/IMG_0725 Large.jpeg',
        '/images/large/IMG_0726 Large.jpeg',
        '/images/large/IMG_0884 Large.jpeg',
        '/images/large/IMG_1011 Large.jpeg',
        '/images/large/IMG_1112 Large.jpeg',
        '/images/large/IMG_1165 Large.jpeg',
        '/images/large/IMG_1166 Large.jpeg',
        '/images/large/IMG_1275 Large.jpeg',
        '/images/large/IMG_1416 Large.jpeg',
        '/images/large/IMG_1437 Large.jpeg',
        '/images/large/IMG_1438 Large.jpeg',
        '/images/large/IMG_1441 Large.jpeg',
        '/images/large/IMG_1444 Large.jpeg',
        '/images/large/IMG_1532 Large.jpeg',
        '/images/large/IMG_1589 Large.jpeg',
        '/images/large/IMG_1624 Large.jpeg',
        '/images/large/IMG_1644 Large.jpeg',
        '/images/large/IMG_1879 Large.jpeg',
        '/images/large/IMG_1881 Large.jpeg',
        '/images/large/IMG_1885 Large.jpeg',
        '/images/large/IMG_1943 Large.jpeg',
        '/images/large/IMG_1974 Large.jpeg',
        '/images/large/IMG_1977 Large.jpeg',
        '/images/large/IMG_1994 Large.jpeg',
        '/images/large/IMG_2011 Large.jpeg',
        '/images/large/IMG_2047 Large.jpeg',
        '/images/large/IMG_2049 Large.jpeg',
        '/images/large/IMG_2056 Large.jpeg',
        '/images/large/IMG_2268 Large.jpeg',
        '/images/large/IMG_2279 Large.jpeg',
        '/images/large/IMG_2298 Large.jpeg',
        '/images/large/IMG_2299 Large.jpeg',
        '/images/large/IMG_A_0045 Large.jpeg',
        '/images/large/IMG_A_0149 Large.jpeg',
        '/images/large/IMG_A_0161 Large.jpeg',
        '/images/large/IMG_A_0163 Large.jpeg',
        '/images/large/IMG_A_0404 Large.jpeg',
        '/images/large/IMG_A_0410 Large.jpeg',
        '/images/large/IMG_A_0412 Large.jpeg',
        '/images/large/IMG_A_0423 Large.jpeg',
        '/images/large/2013-08-10 18.26.09 Large.jpeg',
        '/images/large/20140401_144449 Large.jpeg',
        '/images/large/20140401_144738 Large.jpeg',
      ],
    }
  },
};
</script>
