<template>
  <div class="container">
    <div class="mt-3 mb-4">
      <h1 class="fs-2">JBC INC</h1>
      <h2 class="fs-4 lead">Construction Services</h2>
      <h3 class="fs-5 lead">Serving the Tampa Bay area since 1996</h3>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-6 my-3">
        <ul class="list-group p-0">
          <li class="list-group-item">
            <span class="fw-bold">Our services include:</span>
          </li>
          <li class="list-group-item" v-for="item in services" :key="item">
            <span>{{item}}</span>
          </li>
        </ul>          
      </div>

      <div class="col-lg-6 my-3">
        <div class="card">
          <div class="card-body p-0 m-3">
            <img src="/images/large/20141205_153046 Large.jpeg" style="width: -webkit-fill-available" class="rounded-2">

            <hr>

            <div class="col my-2">
              <button type="button" class="btn btn-primary w-100" data-bs-toggle="modal" data-bs-target="#imgGalleryModal">
                View Photo Gallery
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center my-5">
      <p class="fs-4 mb-1">Call for a free estimate</p>
      <p class="fs-4">727-916-1317</p>
    </div>

    <PhotoGalleryModal v-if="loadGallery"/>
  </div>
</template>
<script>
import PhotoGalleryModal from '@/components/PhotoGalleryModal.vue'

export default {
  name: 'Cover',
  components: { PhotoGalleryModal },
  mounted() {
    this.loadGallery = true;
  },
  data() {
    return {
      loadGallery: false,
      services: [
        'Project Management',
        'Kitchen and Bath remodel',
        'Interior/Exterior Painting',
        'Carpentry Services (Crown molding- doors & frames- Chair-rail -Baseboards -Wainscot)',
        'Flooring (Luxury Vinyl - Wood - Laminate - Tile - Marble/travertine)',
        'Drywall installation/Repair',
        'Exterior Repairs (Hardie Siding - Stucco)',
        'Handyman Services',
        'Property Management/Real Estate Services',
        'Now Offering Solar Energy (Rooftop Solar Panels and Battery Backup Systems)',
      ],
    }
  }
};
</script>
