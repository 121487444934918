<template>
  <Cover/>
</template>

<script>
import Cover from '@/components/Cover.vue'

export default {
  name: 'Home',
  components: {
    Cover
  }
}
</script>
