<template>
  <div class="modal fade" id="imgGalleryModal" tabindex="-1" aria-labelledby="imgGalleryModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header bg-light">
          <h5 class="modal-title" id="imgGalleryModalLabel">JBC INC Past Work</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body bg-light p-0">
          <PhotoGallery />
        </div>

        <div class="modal-footer bg-light">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PhotoGallery from '@/components/PhotoGallery.vue'

export default {
  name: 'PhotoGalleryModal',
  components: { PhotoGallery }
};
</script>